import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import * as classes from "./downloads.module.css"

export default function Downloads({ data }) {
  return (
    <Layout>
      <Helmet>
        <title>Quartz - Downloads</title>
      </Helmet>
      <main className={classes.downloads}>
        <h1>Downloads</h1>
        <ul className={classes.downloadLinks}>
          <li>
            <a href="/Automatic-Manual.pdf">Automatic Washer Manual</a>
          </li>
          <li>
            <a href="/Washer-Dryer-Manual.pdf">Automatic Washer Dryer Manual</a>
          </li>
          <li>
            <a href="/Semi-Automatic-Manual.pdf">Semi-Automatic Manual</a>
          </li>
        </ul>
      </main>
    </Layout>
  )
}
